import React, { useRef } from "react";
import "./proyectos.css";
import flecha from "../images/right-side-arrow.webp"
import oceanwifi from "../images/oceanwifi.png"
// import eccaedu from "../images/eccaedu.png";

function Proyectos() {


  return (
    <>
      <section id="proyectos" className="proyectos">
      
      <aside>
        <h5>Poseo experiencia en el desarrollo de aplicaciones web utilizando React para interfaces interactivas y Laravel para la creación de backend robustos. <br /> He participado en proyectos con empresas destacadas en Canarias.</h5>

      <div className="">
        
        <img src={oceanwifi} alt="" id="oceanwifi"/>
        <img src={flecha} alt="" id="flechaProyecto"/>
        <p className="tituloProyecto">Proyectos Destacados</p>
        <p className="proyectoDestacado">Oceanwifi | Naviera Armas</p>
        {/* <a href="/proyectos/eccaedu" className="btnProyecto">Ver Proyecto</a> */}
        
        
        </div>
      </aside>
      


      </section>
    </>
  );
}

export default Proyectos;