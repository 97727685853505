import './App.css';
import Cursor from './components/cursorFollower';
import Navbar from './components/Navbar';
import Home from './components/home';
import Tecnologias from './components/tecnologias';
import Acordeon from './components/experiencia';
import 'bootstrap/dist/css/bootstrap.min.css';
import Contacto from './components/contact';
import Proyectos from './components/proyectos';
import Footer from './components/Footer';


function App() {

  return (
    <div className="App">
      <Cursor/>
      <Navbar/>
      <Home/>
      <Tecnologias/>
      <Proyectos/>
      <Acordeon/>
      <Contacto/>
      <Footer/>
      
    </div>
  );

}

export default App;