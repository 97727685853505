import React, { Component } from "react";
import logo from "../images/logo.svg";
import "./footer.css";


class Footer extends Component {
  

  render() {
    return (
      <>
        <footer>
          <img src={logo} />

          <p className="enlacesFooter">
            <a href="https://www.linkedin.com/in/jonaygc/" alt="Perfil profesional en LinkedIn" target="_blank"><i className="fa-brands fa-linkedin-in"></i></a>
            <a href="https://github.com/rShuraa" alt="Repositorio de proyectos en GitHub"  target="_blank"><i className="fa-brands fa-github"></i></a>
            <a href="#contacto" alt="Enviar mensaje directo"><i className="fa-solid fa-envelope"></i></a>
            <a href="tel:609708523" alt="Llamar al número de teléfono"><i className="fa-solid fa-phone"></i></a>
          </p>

          <p className="copyright"> <span>© JONAY GALINDO 2024</span> <span className="separador">|</span><span> Todos los derechos reservados</span></p>
        </footer>
      </>
    );
  }
}

export default Footer;
